<script>
  import linkMixin from '@/mixins/linkMixin'

  /**
   * A card containing an image (optionally a slider), a body, and a button.
   */
  export default {
    mixins: [linkMixin],
    props: {
      /** Whether the card has a border. */
      hasBorder: {
        type: Boolean,
        default: false,
      },
      /** Whether the price should appear below the kicker, title, and description. Seen in ProductTile */
      priceLast: {
        type: Boolean,
        default: false,
      },
      /** Whether the price should be centered in the card. */
      priceCentered: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        descriptionExpanded: false,
      }
    },
    methods: {
      hasBody() {
        return (
          this.$scopedSlots.default ||
          this.$scopedSlots.price ||
          this.$scopedSlots.kicker ||
          this.$scopedSlots.title ||
          this.$scopedSlots.subtitle ||
          this.$scopedSlots.description
        )
      },
      hasExpandButton() {
        return this.$scopedSlots.description && !this.hasLink
      },
      emitDetails() {
        this.$emit('clickDetails')
      },
    },
  }
</script>

<template>
  <article
    :class="`flex flex-col flex-grow rounded-sm ${
      hasBorder ? 'bg-white' : ''
    } font-normal text-base leading-normal`"
    @click="$emit('click')"
  >
    <BaseLink
      class="static flex flex-col flex-grow rounded-sm overflow-hidden"
      v-bind="linkProps"
      v-on="!hasLink ? { click: emitDetails } : {}"
    >
      <div
        v-if="hasBorder"
        :class="`z-10 absolute inset-0 rounded-sm border border-gray-active transition duration-200 pointer-events-none`"
      />

      <div v-if="$scopedSlots.image" class="bg-gray-lightest">
        <!-- @slot Optional. A full-width image above the card’s body. Should contain a `BaseImage` component. -->
        <slot name="image" />

        <BaseTagList v-if="$scopedSlots.tags" class="absolute z-10 top-0 mt-2 left-0 ml-2">
          <!-- @slot Optional. A list of tags on top of the image. Should contain one or multiple tags. -->
          <slot name="tags" />
        </BaseTagList>

        <div
          v-if="$scopedSlots.rank"
          class="absolute z-10 bottom-0 left-0 flex items-center justify-center w-10 h-10 bg-summer text-white text-4xl font-heading font-medium text-center leading-none pointer-events-none"
        >
          <!-- @slot Optional. A large number displayed in the bottom left corner of the card. Should not be longer than 2 characters. -->
          <slot name="rank" />
        </div>

        <!-- @slot Optional. A small discount rate displayed in the bottom left corner of the card. Should not be longer than 2 characters and has '% off' appended to the end -->
        <div
          v-if="$scopedSlots.discount"
          class="absolute bottom-0 left-0 bg-black text-white text-center text-xs md:text-base"
        >
          <slot name="discount" />
        </div>
        <!-- @slot Optional. An out of stock badge at the bottom of the image on the card -->
        <div
          v-if="$scopedSlots.soldOut"
          class="absolute bottom-0 left-0 bg-dawn italic font-body font-semibold text-xs md:text-base text-black text-center"
          :style="{ lineHeight: '24px' }"
        >
          <slot name="soldOut" />
        </div>
      </div>

      <!-- Not all cards need padding around their information. bodyHasPadding allows us to decide when it's present. BaseCard defaults to `false`. BaseCardSelectable defaults to `true`. -->
      <div
        v-if="hasBody()"
        :class="`flex flex-col flex-grow ${hasBorder ? 'pt-3 md:pt-1 px-1 md:px-2' : 'pt-5px'}`"
      >
        <!-- @slot Optional. The card’s body. Defaults to the information provided by the more specific slots, namely `price`, `kicker`, `title`, `subtitle`, and `description`. Can contain anything. -->
        <slot>
          <div :class="`${hasExpandButton() ? 'flex justify-between' : ''} flex-grow`">
            <div
              :class="`-ml-2 flex flex-row-reverse justify-between flex-wrap ${
                priceLast ? 'flex-col' : null
              }`"
            >
              <div
                v-if="$scopedSlots.price"
                :class="` flex flex-grow flex-shrink-0 ${priceLast ? 'order-1' : null} ${
                  priceCentered ? 'justify-center' : 'ml-2 mr-auto'
                }`"
              >
                <!-- @slot Optional. The price of the product, appears above the title and kicker, or on the right if there's enough space. Should contain text. -->
                <slot name="price" />
              </div>

              <div class="ml-2 w-60 max-w-full flex-grow-infinity leading-snug">
                <div
                  v-if="$scopedSlots.kicker"
                  class="mb-1px font-normal text-gray-opacity68 overflow-hidden ellipsis"
                >
                  <!-- @slot Optional. The kicker text, which appears above the title, in smaller characters. Should contain text. -->
                  <slot name="kicker" />
                </div>

                <div
                  v-if="$scopedSlots.title"
                  :class="`${
                    !priceLast ? 'min-h-9' : null
                  } leading-tight overflow-hidden ellipsis line-clamp-2`"
                >
                  <!-- @slot Optional. The title of the card. Should contain text. -->
                  <slot name="title" />
                </div>

                <h3
                  v-if="$scopedSlots.subtitle"
                  class="mt-1 text-sm text-gray-dark overflow-hidden ellipsis"
                >
                  <!-- @slot Optional. The subtitle of the card, which appears below the title. Should contain text. -->
                  <slot name="subtitle" />
                </h3>
              </div>
            </div>

            <div
              v-if="hasExpandButton()"
              :class="`ml-2 ${hasBorder ? '-mr-2' : ''} -mt-2 flex flex-shrink-0`"
            >
              <BaseIconClickable
                label="More information"
                :size="6"
                @click="descriptionExpanded = !descriptionExpanded"
              >
                <IconChevronUp v-if="descriptionExpanded" />
                <IconInfo v-else />
              </BaseIconClickable>
            </div>
          </div>

          <BaseSlideUpDown
            v-if="$scopedSlots.description"
            :expanded="descriptionExpanded || hasLink"
          >
            <div class="pt-3">
              <BaseRichText leading="tight" class="text-gray-darkest">
                <!-- @slot Optional. A description for the thing represented by the card, which is hidden by default but can be toggled by the user. Should contain rich text (see the tags and components allowed inside `BaseRichText`). -->
                <slot name="description" />
              </BaseRichText>
            </div>
          </BaseSlideUpDown>
        </slot>
      </div>
    </BaseLink>

    <footer v-if="$scopedSlots.footer" class="static">
      <!-- @slot Optional. The card’s footer. A button in most use cases. Can accept any content.-->
      <slot name="footer" />
    </footer>
  </article>
</template>
